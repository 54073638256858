import React from "react";
import Mainnavbar from "./Mainnavbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container-fluid">
        <Mainnavbar />
       

        <div className="mt-5 container">
          <h2 className="text-center">Privacy Policy for ImageDynamics</h2>

          <section className="my-4">
            <h3>Introduction</h3>
            <p>
              At <strong>ImageDynamics</strong>, we value your privacy. This Privacy Policy
              explains what information we collect and how it is used when you visit
              our website. By using our website, you agree to this policy.
            </p>
          </section>

          <section className="my-4">
            <h3>Information We Collect</h3>
            <p>
              <strong>ImageDynamics</strong> does not collect any personal information such
              as your name, email address, or other personally identifiable data.
              However, we do use third-party services like Google AdSense to serve
              advertisements, which may collect non-personal information.
            </p>
          </section>

          <section className="my-4">
            <h3>Google AdSense and Cookies</h3>
            <p>
              We use <strong>Google AdSense</strong> to display ads on our website. Google,
              as a third-party vendor, uses cookies to serve ads based on your prior
              visits to our website or other websites.
            </p>
            <ul>
              <li>
                <strong>Cookies:</strong> Cookies are small text files stored on your
                device that collect information about your browsing behavior.
                Google’s use of advertising cookies enables it and its partners to
                serve ads based on your visit to this site and/or other sites on the
                Internet.
              </li>
              <li>
                <strong>Personalized Ads:</strong> Google may use cookies to track your
                visits and provide personalized ads. However, you can opt out of
                personalized ads by visiting{" "}
                <a href="https://adssettings.google.com" target="_blank" rel="noopener noreferrer">
                  Google Ad Settings
                </a>.
              </li>
            </ul>
          </section>

          <section className="my-4">
            <h3>Third-Party Links</h3>
            <p>
              Our website may contain links to external sites that are not operated by us.
              We have no control over the content and practices of these sites and
              cannot be responsible for their privacy policies.
            </p>
          </section>

          <section className="my-4">
            <h3>Changes to This Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. Any changes will be
              posted on this page, and the "Last Updated" date will reflect the changes.
            </p>
          </section>

          <section className="my-4">
            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about this Privacy Policy, please
              contact us at 10023946manas@gmail.com .
            </p>
          </section>

         
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
