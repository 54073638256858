import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faDownload,
  faUpload,
  faQuestionCircle,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { compress } from "image-conversion";
import { Spinner } from "react-bootstrap";
import Mainnavbar from "./Mainnavbar";
import Footer from "./Footer";

function CompressorComp() {
  const [compressedLink, setCompressedLink] = useState("");
  const [originalImage, setOriginalImage] = useState(null);
  const [originalLink, setOriginalLink] = useState("");
  const [uploadImage, setUploadImage] = useState(false);
  const [outputFileName, setOutputFileName] = useState("");
  const [compressionQuality, setCompressionQuality] = useState(0.8);
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);
  const [isCompressed, setIsCompressed] = useState(false);
  const [compressionInProgress, setCompressionInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [compressedHistory, setCompressedHistory] = useState([]);
  const [showCompressedImage, setShowCompressedImage] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (originalImage) {
      setCompressedLink("");
      setCompressedSize(0);
      setIsCompressed(false);
      setShowCompressedImage(false);
    }
  }, [originalImage]);

  useEffect(() => {
    if (originalImage && compressionQuality) {
      // Recalculate compressed size when quality slider changes
      const calculateCompressedSize = async () => {
        const img = new Image();
        img.src = URL.createObjectURL(originalImage);
        img.onload = async () => {
          const originalWidth = img.width;
          const originalHeight = img.height;
          const compressedImage = await compress(originalImage, {
            quality: compressionQuality,
            width: originalWidth,
            height: originalHeight,
          });
          setCompressedSize(compressedImage.size);
        };
      };
      calculateCompressedSize();
    }
  }, [compressionQuality, originalImage]);

  async function uploadLink(event) {
    const imageFile = event.target.files[0];
    setOriginalLink(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
    setUploadImage(true);
    setOriginalSize(imageFile.size);
  }

  async function compressImage() {
    if (!originalImage) {
      alert("Please upload an image first.");
      return;
    }

    try {
      setCompressionInProgress(true);
      setShowCompressedImage(false);
      setLoading(true);

      const img = new Image();
      img.src = URL.createObjectURL(originalImage);
      img.onload = async () => {
        const originalWidth = img.width;
        const originalHeight = img.height;

        const compressedImage = await compress(originalImage, {
          quality: compressionQuality,
          width: originalWidth,
          height: originalHeight,
        });

        setCompressedLink(URL.createObjectURL(compressedImage));
        setCompressedSize(compressedImage.size);
        setIsCompressed(true);
        setCompressedHistory([
          ...compressedHistory,
          {
            link: compressedLink,
            name: outputFileName,
          },
        ]);

        setTimeout(() => {
          setLoading(false);
          setShowCompressedImage(true);
        }, 2000);
      };
    } catch (error) {
      console.error("Image compression failed:", error);
      alert("Image compression failed. Please try again.");
    } finally {
      setCompressionInProgress(false);
    }
  }

  function resetApp() {
    setOriginalLink("");
    setOriginalImage(null);
    setUploadImage(false);
    setOutputFileName("");
    setCompressionQuality(0.8);
    setOriginalSize(0);
    setCompressedSize(0);
    setIsCompressed(false);
    setCompressedLink("");
    setShowCompressedImage(false);
  }

  function toggleHelp() {
    setShowHelp(!showHelp);
  }

  function toggleHistory() {
    setShowHistory(!showHistory);
  }

  return (
    <div className="background_compresser  ">
         <div className="container-fluid ">
      <div>
        <Mainnavbar />
      </div>

      <div className="row maring_top_row ">
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <div>Ads</div>
        </div>  
        <div className="col-md-8 p-3 ">
        <div className="card noborder_import  padding_card_section_common  ">
          {/* Navbar Section */}
          <div className="navbar border_comppress_title   mb-4 d-flex justify-content-between align-items-center">
            <div className="m-0 ">
              <h2 className="red_color_text_class textdecoration_title">Compressor Your Image</h2>
            <div className="margin_minus_imagecompresser  subtitle_text_compressor text-muted">This Tool compresses images to reduce file size without quality loss.</div>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="mx-2  circle-background_icons red_color_class_home"
                onClick={toggleHelp}
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                icon={faHistory}
                className="mx-2 red_color_class_home circle-background_icons "
                onClick={toggleHistory}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          {/* Help Section */}
          {showHelp && (
            <div className="alert alert-info">
              <h5>Instructions:</h5>
              <ul>
                <li>Upload an image using the "Upload a file" button.</li>
                <li>Adjust the compression quality using the slider.</li>
                <li>Press the "Compress" button to start the compression.</li>
                <li>
                  Download the compressed image using the "Download" button.
                </li>
              </ul>
            </div>
          )}

          {/* History Section */}
          {showHistory && (
            <div className="alert alert-secondary">
              <h5>Compressed History:</h5>
              <ul className="list-unstyled">
                {compressedHistory.map((item, index) => (
                  <li key={index}>
                    <a href={item.link} download={item.name}>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Main Content Section */}
          <div className="row">
            {/* Upload Section */}
            <div className="col-md-6 text-center">
              {uploadImage ? (
                <img
                  src={originalLink}
                  alt="Original"
                  className="img-fluid image_compress_image mb-3"
                />
              ) : (
                <div className="border box_uplod  mb-3">
                  <FontAwesomeIcon icon={faUpload} size="3x" className="iconcolor_upload" />
                </div>
              )}
              <div>
                <label className="btn button_desoing mb-3">
                 
                  <span className="mx-1 ">Upload a file</span>
                  <input
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={uploadLink}
                  />
                </label>
            
              </div>
            </div>

            {/* Compression Settings Section */}
          

            {/* Compressed Image Section */}
            <div className="col-md-6 text-center mb-3">
              {showCompressedImage ? (
                <>
                  <img
                    src={compressedLink}
                    alt="Compressed"
                    className="img-fluid mb-3  "
                    onClick={() => setModalShow(true)}
                    style={{ cursor: "pointer" }}
                  />
                  <a
                    href={compressedLink}
                    download={outputFileName}
                    className="btn btn-success button_desoing"
                  >
                   
                    Download
                  </a>
                  {/* Modal for full image view */}
                  {modalShow && (
                    <div
                      className="modal fade show d-block"
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-body text-center">
                            <img
                              src={compressedLink}
                              alt="Compressed"
                              className="img-fluid"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => setModalShow(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center box_uplod border p-5">
                  {compressionInProgress ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <FontAwesomeIcon icon={faImage} size="3x" />
                  )}
                </div>
              )}
            </div>
          </div>
          
         
          {outputFileName && (
  <div className="card py-3 mt-3">
    <div className="row">
      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="form-group text-center">
          <label htmlFor="qualitySlider" className="mb-2 me-3 fs-5 red_color_text_class">Compression Quality :</label>
          <input
            type="range"
            className="form-control-range "
            min="0.1"
            max="1"
            step="0.1"
            value={compressionQuality}
            onChange={(event) =>
              setCompressionQuality(parseFloat(event.target.value))
            }
          />
        </div>
      </div>

      <div className="col-md-6">
        <div className="mt-3 text-center">
          <p>Original Size: {Math.round(originalSize / 1024)} KB</p>
          <p>Compressed Size: {Math.round(compressedSize / 1024)} KB</p>

          {isCompressed && !compressionInProgress && (
            <p className="text-success">Image compressed successfully!</p>
          )}

          {compressionInProgress && (
            <p className="text-info">
              <span className="spinner-border spinner-border-sm me-2 red_color_text_class" role="status" />
              Compressing image...
            </p>
          )}
        </div>

        <div className="mt-3 text-center">
          {loading ? (
            <div className="text-info red_color_text_class">
              <span className="spinner-border spinner-border-sm me-2 red_color_text_class" role="status" />
              Loading...
            </div>
          ) : (
            <button
              className="btn compressbuttons_desgin mr-2"
              onClick={compressImage}
            >
              <FontAwesomeIcon icon={faImage} className="mr-2" />
              <span className="mx-1">Compress</span>
            </button>
          )}

          <button className="btn compressbuttons_desgin" onClick={resetApp}>
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
)}

            
          
        
        </div>
        </div>
       
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <div>Ads</div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center margin_top_bottom_abd">
        <div>ads</div>
      </div>
    </div>
    </div>
   
  );
}

export default CompressorComp;
