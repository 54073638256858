import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "./Footer";
import logo from "../assets/Group 6.png";
import line from "../assets/Group 7.png"

const Homesection = () => {
  useEffect(() => {
    // Ensure icons start falling immediately on page load
    const icons = document.querySelectorAll(".falling-icon");
    icons.forEach((icon, index) => {
      const delay = Math.random() * 2; // Random delay to avoid simultaneous fall
      icon.style.animationDelay = `${delay}s`;
    });
  }, []);

  const datawiththecntain = [
    {
      id: "1",
      text: "Compresser",
      link: "/Imagecompresser",
      icon: "fas fa-compress",
      color_class: "red_color_class_home",
      color_text_class: "red_color_text_class",
      color: "rgba(255, 0, 0, 0.3)", // Red color
      summary: "Compress your images easily with the best compression tools.",
    },
    {
      id: "2",
      text: "Aspect Ratio",
      link: "/AspectRatio",
      icon: "fas fa-expand",
      color_class: "blue_color_class_home",
      color_text_class: "blue_color_text_class",
      color: "rgba(0, 0, 255, 0.3)", // Blue color
      summary: "Adjust the aspect ratio of your images effortlessly.",
    },
    {
      id: "3",
      text: "Image Filter",
      link: "/imagetfilter",
      icon: "fas fa-filter",
      color_class: "pink_color_class_home",
      color_text_class: "pink_color_text_class",
      color: "rgba(255, 105, 180, 0.3)", // Pink color
      summary: "Apply a variety of filters to your images.",
    },
    {
      id: "4",
      text: "Image to Text",
      link: "/imagetotext",
      icon: "fas fa-file-alt",
      color_class: "green_color_class_home",
      color_text_class: "green_color_text_class",
      color: "rgba(0, 255, 0, 0.3)", // Green color
      summary: "Extract text from your images with OCR technology.",
    },
    {
      id: "5",
      text: "Image to PDF",
      link: "/imagetotpdf",
      icon: "fas fa-file-pdf",
      color_class: "purple_color_class_home",
      color_text_class: "purple_color_text_class",
      color: "rgba(128, 0, 128, 0.3)", // Purple color
      summary: "Convert your images into a PDF document.",
    },
    {
      id: "6",
      text: "Image to PNG",
      link: "/imagetoPng",
      icon: "fas fa-file-image",
      color_class: "orange_color_class_home",
      color_text_class: "orange_color_text_class",
      color: "rgba(255, 165, 0, 0.3)", // Orange color
      summary: "Convert your images to PNG format.",
    },
  ];

  // Function to handle mouse movement and update the circle's position and color
  const handleMouseMove = (e, id, color) => {
    const card = document.getElementById(`card-${id}`);
    const circle = document.getElementById(`circle-${id}`);

    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    circle.style.top = `${y}px`;
    circle.style.left = `${x}px`;
    circle.style.opacity = "1"; // Show the circle
    circle.style.backgroundColor = color; // Set the dynamic color

    // Add class to hide the cursor
    card.classList.add("hide-cursor");
  };

  // Function to hide the circle when the mouse leaves the card
  const handleMouseLeave = (id) => {
    const circle = document.getElementById(`circle-${id}`);
    circle.style.opacity = "0"; // Hide the circle

    // Remove class to show the cursor
    const card = document.getElementById(`card-${id}`);
    card.classList.remove("hide-cursor");
  };

  return (
    <div className="container-fluid background_homesection">
      {/* Falling icons */}
      <i className="fas fa-file-image falling-icon purple_color_text_class_icon_falling"></i>
      <i className=" fas fa-compress falling-icon orange_color_text_class_icon_falling"></i>
      <i className="fas  fa-expand falling-icon red_color_text_class_icon_falling"></i>
      <i className="fas fa-file-alt falling-icon pink_color_text_class_icon_falling"></i>
      <i className="fas fa-filter falling-icon blue_color_text_class_icon_falling"></i>
      <i className="fas  fa-file-pdf falling-icon green_color_text_class_icon_falling"></i>

      {/* Header Section */}
      <div className="text-center my-4">
       <img src={logo} className="widht_hieght_home_section_logo"/>
        
   
      </div>

      {/* Main Content Section */}
      <div className="row gx-4 gy-5">
        {/* Left Ads Column */}
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <div className="bg-light p-3 rounded shadow-sm text-center">Ads</div>
        </div>

        {/* Center Content Column */}
        <div className="col-md-8">
          <div className="row">
            {datawiththecntain.map((item) => (
              <Link
                className="col-md-4  mb-4 nodecoration col_centerthing_homesection"
                to={item.link}
                key={item.id}
                onMouseMove={(e) => handleMouseMove(e, item.id, item.color)}
                onMouseLeave={() => handleMouseLeave(item.id)}
              >
                <div
                  className={`card h-100 card_hover width_hieght_col_homesection rounded-4 py-1 px-3 ${item.color_class}`}
                  id={`card-${item.id}`}
                >
                  <div className="circle" id={`circle-${item.id}`}></div>{" "}
                  {/* Circle */}
                  <div className="card-body ">
                    {/* Font Awesome Icon */}
                    <div className="tools__item__icon_css my-4 d-flex justify-content-center align-items-center">
                      <i
                        className={`${item.icon} fa-2x ${item.color_text_class}`}
                      ></i>
                    </div>
                    {/* Link Title */}
                    <Link
                      to={item.link}
                      className={`h5 d-block mb-2 nodecoration_text ${item.color_text_class}`}
                    >
                      {item.text}
                    </Link>
                    {/* Short Summary */}
                    <p className="text-muted text_decorantion_summary">
                      {item.summary}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* Right Ads Column */}
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <div className="bg-light p-3 rounded shadow-sm text-center">Ads</div>
        </div>
      </div>

      {/* Bottom Ads Section */}
      <div className="d-flex align-items-center justify-content-center mt-5 mb-4">
        <div className="bg-light p-3 rounded shadow-sm text-center">Ads</div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Homesection;
