import React from "react";
import "./App.css";
import CompressorComp from "./components/Compresser";
import "bootstrap/dist/css/bootstrap.css";
import { Route, Routes } from "react-router-dom";
import AspectRatio from "./components/AspectRatio";
import ImagetoTex from "./components/ImagetoTex";
import ImagetoPDF from "./components/imagetoPDF";
import Homesection from "./components/Homesection";
import ImageMainPage from "./components/imagetofilter/imagemainpage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ImageToPNGConverter from "./components/imagetoPng";
import ScrollToTop from "./components/Scrolltothetop"; // Import ScrollToTop component

function App() {
  return (
    <div>
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <Routes>
        <Route path="/" element={<Homesection />} />
        <Route path="/Imagecompresser" element={<CompressorComp />} />
        <Route path="/AspectRatio" element={<AspectRatio />} />
        <Route path="/imagetotext" element={<ImagetoTex />} />
        <Route path="/imagetotpdf" element={<ImagetoPDF />} />
        <Route path="/imagetfilter" element={<ImageMainPage />} />
        <Route path="/Privacypiolicy" element={<PrivacyPolicy />} />
        <Route path="/imagetoPng" element={<ImageToPNGConverter />} />
      </Routes>
    </div>
  );
}

export default App;
