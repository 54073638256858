import React from 'react';

export default function SidebarItem({ name, active, handleClick }) {
    return (
        <button
            className={`sidebar-item compressbuttons_desgin_slider_button my-2 ${active ? 'active button_desoing_pink ' : ''}`}
            onClick={handleClick}
        > 
            {name}
        </button>
    );
}
