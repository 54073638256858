import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/Group 6.png"

const Mainnavbar = () => {
  return (
    <>
          <nav className="navbar navbar-expand-lg py-3 borderofthenavbar  px-3">
  <div className="container-fluid">
    <NavLink className="navbar-brand fs-4 mb-2 d-flex justify-content-center align-items-center " to="/">
      <img src={logo} className="width_hieght_logo" />
    </NavLink>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav mx-auto d-flex gap-4">
        <li className="nav-item fontsize_links">
          <NavLink
            to="/Imagecompresser"
            className={({ isActive }) =>
              `nav-link ${isActive ? "active-link border_line" : ""}`
            }
          >
            <i className="fas fa-compress pe-2"></i>
            Compresser
            
          </NavLink>
        </li>
        <li className="nav-item fontsize_links">
          <NavLink
            to="/AspectRatio"
            className={({ isActive }) =>
              `nav-link ${isActive ? "active-link-blue border_line" : ""}`
            }
          >
              <i className="fas fa-expand pe-2"></i>
            Resize Image
          </NavLink>
        </li>
        <li className="nav-item fontsize_links">
          <NavLink
            to="/imagetfilter"
            className={({ isActive }) =>
              `nav-link ${isActive ? "active-link-pink border_line" : ""}`
            }
          >
              <i className="fas fa-filter pe-2"></i>
            Image Filter
          </NavLink>
        </li>
        <li className="nav-item fontsize_links">
          <NavLink
            to="/imagetotext"
            className={({ isActive }) =>
              `nav-link ${isActive ? "active-link-green border_line" : ""}`
            }
          >
              <i className="fas fa-file-alt pe-2"></i>
            Image to Text
          </NavLink>
        </li>
        <li className="nav-item fontsize_links">
          <NavLink
            to="/imagetotpdf"
            className={({ isActive }) =>
              `nav-link ${isActive ? "active-link-purple border_line" : ""}`
            }
          >
              <i className="fas fa-file-pdf pe-2"></i>
            Image to PDF
          </NavLink>
        </li>
    
        <li className="nav-item fontsize_links">
          <NavLink
            to="/imagetoPng"
            className={({ isActive }) =>
              `nav-link ${isActive ? "active-link-orange border_line" : ""}`
            }
          >
              <i className="fas fa-file-image pe-2"></i>
            Image To Png
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
</nav>

    



  </>



  );
};

export default Mainnavbar;
