import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
            <div className=''>
                <div className='bggray d-flex justify-content-between align-items-center px-5'>
                        <div className=''>
                            Other sites we Created : <a href='https://dorean.solutions/' className='text-danger text-decoration-none'>
                            https://dorean.solutions/
                                </a> 
                        </div>
                        <div className=''>
                                <Link to="/Privacypiolicy" className='text-danger text-decoration-none' >
                                        Privacy policy
                                </Link>
                        </div>
                </div>
            </div>
    </div>
  )
}

export default Footer
