import React, { useRef, useState } from "react";
import Mainnavbar from "./Mainnavbar";

const ImageToPNGConverter = () => {
  const canvasRef = useRef(null);
  const [images, setImages] = useState([]);

  // Handle image upload and add to images array
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const newImages = [...images];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = () => {
        setImages((prevImages) => [
          ...prevImages,
          { data: reader.result, name: file.name },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle drag-and-drop
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = () => {
        setImages((prevImages) => [
          ...prevImages,
          { data: reader.result, name: file.name },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Convert image to PNG and prepare for download
  const convertToPNG = (imageSrc, index) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      const pngDataUrl = canvas.toDataURL("image/png");
      const updatedImages = [...images];
      updatedImages[index].pngData = pngDataUrl;
      setImages(updatedImages);
    };
  };

  // Remove image from the list
  const handleDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  return (
    <div className="background_compresser_orange">
      <div className="container-fluid">
        <div>
          <Mainnavbar />
        </div>

        <div className="row maring_top_row">
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            ads
          </div>
          <div className="col-md-8 p-3">
          <div className=" card padding_card_section_common">
            <div className="card mb-4">
              <div className="card-body card_pdf_dflex">
                <div> 
                <h3 className="orange_color_text_class  mb-0">Image to PNG Converter</h3>
                <div className="text-muted">
                This Tool converts images to PNG format quickly and efficiently.
                </div>

                </div>
                
                <div className="ms_auto_button">
                  <label htmlFor="image-upload" className="btn button_desoing_orange">
                    Upload Images
                  </label>
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>

            {/* Drag and Drop Section */}
            <div
              className="border p-5 mb-4 text-center"
              style={{ borderStyle: "dashed", backgroundColor: "#f9f9f9" }}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              {images.length === 0 ? (
                <span className="text-muted">Drag and drop images here</span>
              ) : (
                <div className="row">
                  {images.map((image, index) => (
                    <div key={index} className="col-12 col-md-5 mb-3">
                      <div className="card ">
                        <img
                          src={image.data}
                          alt={image.name}
                          className="card-img-top"
                        />
                        <div className="card-body">
                          <h6 className="card-title text-truncate">
                            {image.name}
                          </h6>
                          <button
                            className="btn compressbuttons_desgin_png"
                            onClick={() => handleDelete(index)}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                          {image.pngData ? (
                            <a
                              href={image.pngData}
                              download={`converted-image-${index + 1}.png`}
                              className="btn button_desoing_orange mt-2"
                            >
                              Download PNG
                            </a>
                          ) : (
                            <button
                              className="btn button_desoing_orange mt-2"
                              onClick={() => convertToPNG(image.data, index)}
                            >
                              Convert to PNG
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          </div>

          

          <div className="col-md-2 d-flex justify-content-center align-items-center">
            ads
          </div>
        </div>

        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

        <div className="d-flex align-items-center justify-content-center my-4">
          <div>ads</div>
        </div>
      </div>
    </div>
  );
};

export default ImageToPNGConverter;
