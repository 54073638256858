import React, { useState, useMemo, useRef } from "react";

import Slider from "./filterslider";
import SidebarItem from "./filtgersidebaritem";
import Mainnavbar from "../Mainnavbar";

const DEFAULT_OPTIONS = [
  {
    name: "Brightness",
    property: "brightness",
    value: 100,
    range: {
      min: 0,
      max: 200,
    },
    unit: "%",
  },
  {
    name: "Contrast",
    property: "contrast",
    value: 100,
    range: {
      min: 0,
      max: 200,
    },
    unit: "%",
  },
  {
    name: "Saturation",
    property: "saturate",
    value: 100,
    range: {
      min: 0,
      max: 200,
    },
    unit: "%",
  },
  {
    name: "Grayscale",
    property: "grayscale",
    value: 0,
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "Sepia",
    property: "sepia",
    value: 0,
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "Hue Rotate",
    property: "hue-rotate",
    value: 0,
    range: {
      min: 0,
      max: 360,
    },
    unit: "deg",
  },
  {
    name: "Blur",
    property: "blur",
    value: 0,
    range: {
      min: 0,
      max: 20,
    },
    unit: "px",
  },
];

function ImageMainPage() {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const [image, setImage] = useState(null); // State to store uploaded image
  const imageRef = useRef(null); // Ref for accessing the image element

  const selectedOption = options[selectedOptionIndex];

  function handleSliderChange({ target }) {
    setOptions((prevOptions) => {
      return prevOptions.map((option, index) => {
        if (index !== selectedOptionIndex) return option;
        return { ...option, value: target.value };
      });
    });
  }

  const getImageStyle = useMemo(() => {
    const filters = options.map((option) => {
      return `${option.property}(${option.value}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }, [options]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    if (imageRef.current) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = imageRef.current;

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.filter = getImageStyle.filter;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const link = document.createElement("a");
      link.download = "filtered-image.png";
      link.href = canvas.toDataURL();
      link.click();
    }
  };

  // Function to reset filters to their default values
  const handleReset = () => {
    setOptions(DEFAULT_OPTIONS); // Reset options to default
    setSelectedOptionIndex(0); // Reset the selected option index
  };

  return (
    <div className="background_compresser_pink">
      <div className="container-fluid ">
        <div>
          <Mainnavbar />
        </div>

        <div className="row maring_top_row ">
          <div className="col-md-2 d-flex justify-content-center align-items-center ">
            <div>Ads</div>
          </div>
          <div className="col-md-8 p-3">
            <div className=" card padding_card_section_common">
              <div className="row">
                <h2 className="text-start  pink_color_text_class textdecoration_title ">
                  Filter Your Image
                </h2>
                <div className="text-muted mb-4">
                  This Tool applies various filters to images for enhanced
                  visual effects.
                </div>
                <div className="col-md-9">
                  {/* Upload Button */}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="form-control mb-3 pink_color_text_class pink_color_class_home"
                  />

                  {/* Main Image with applied filters */}
                  <div
                    className="main-image rounded-3"
                    style={{
                      width: "100%",
                      height: "400px",
                      backgroundColor: "#ddd",
                    }}
                  >
                    {image && (
                      <img
                        src={image}
                        alt="Main"
                        style={{
                          ...getImageStyle,
                          width: "100%",
                          height: "100%",
                        }}
                        className="img-fluid"
                        ref={imageRef}
                      />
                    )}
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      {/* Slider component to adjust the selected filter */}
                      <Slider
                        min={selectedOption.range.min}
                        max={selectedOption.range.max}
                        value={selectedOption.value}
                        handleChange={handleSliderChange}
                      />
                    </div>
                    <div className="col-md-6">
                      {image && (
                        <div className="row mt-4">
                          <div className="col">
                            <button
                              className="btn button_desoing_pink me-2"
                              onClick={handleDownload}
                            >
                              Download Image
                            </button>
                            <button
                              className="btn button_desoing_pink"
                              onClick={handleReset}
                            >
                              Reset Filters
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  {/* Sidebar with filter options */}
                  <div className="list-group">
                    {options.map((option, index) => (
                      <SidebarItem
                        key={index}
                        name={option.name}
                        active={index === selectedOptionIndex}
                        handleClick={() => setSelectedOptionIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2 d-flex justify-content-center align-items-center ">
            <div>Ads</div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center my-4">
          <div>ads</div>
        </div>

        {/* Buttons for Download and Reset */}
      </div>
    </div>
  );
}

export default ImageMainPage;
