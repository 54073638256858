import React, { useEffect, useState } from "react";
import Tesseract from "tesseract.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Mainnavbar from "./Mainnavbar";


// Basic text auto-correction and spacing adjustment function
const correctText = (text) => {
  // Replace multiple spaces with a single space
  let correctedText = text.replace(/\s+/g, " ").trim();

  // Correct common OCR mistakes (e.g., replacing '1' with 'I', etc.)
  correctedText = correctedText.replace(/\bl\b/g, "I"); // lowercase 'l' to 'I'
  correctedText = correctedText.replace(/\b0\b/g, "O"); // zero to 'O'

  // Add more corrections as needed or integrate a spell-checker library for a robust solution

  return correctedText;
};

const ImagetoTex = ({ selectedImage }) => {
  const [recognizedText, setRecognizedText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const recognizeText = async () => {
      if (selectedImage) {
        setIsProcessing(true); // Show processing status
        try {
          const result = await Tesseract.recognize(selectedImage, "eng", {
            logger: (m) => console.log(m), // Log progress
            tessedit_char_whitelist:
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", // Optional: Limit recognition to specific characters
          });
          // Process the recognized text for corrections
          const correctedText = correctText(result.data.text);
          setRecognizedText(correctedText);
        } catch (error) {
          console.error("Error recognizing text: ", error);
          setRecognizedText("Error recognizing text");
        } finally {
          setIsProcessing(false); // Stop processing status
        }
      }
    };
    recognizeText();
  }, [selectedImage]);

  return (
    <div className="mt-4">
      <h5>Recognized Text:</h5>
      {isProcessing ? (
        <div className=" green_color_text_class">Processing...</div>
      ) : (
        <p className="border p-3 mb-2">{recognizedText}</p>
      )}
    </div>
  );
};

const ImageUploader = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    setSelectedImage(URL.createObjectURL(image));
  };

  return (
    <div className="background_compresser_green">
        <div className="container-fluid">
      <div>
        <Mainnavbar />
      </div>

      <div className="row maring_top_row">
        <div className="col-md-2 d-flex justify-content-center align-items-center">
          <div>Ads</div>
        </div>

        <div className="p-3 col-md-8">
        <div className="card  padding_card_section_common">
            <h2 className=" green_color_text_class textdecoration_title ">Image to Text Converter</h2>
            <div className="text-muted mb-4">
            This Tool converts uploaded images into text using advanced recognition technology.
            </div>
            <div className="mb-3">
              <label className="form-label">Upload Image</label>
              <input
                type="file"
                className="form-control green_color_text_class green_color_class_home "
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>
            {selectedImage && (
              <div className="text-center">
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="img-thumbnail"
                  style={{ maxWidth: "300px", margin: "10px 0" }}
                />
                <ImagetoTex selectedImage={selectedImage} />
              </div>
            )}
          </div>
        </div>
        
        
        

        <div className="col-md-2 d-flex justify-content-center align-items-center">
          <div>Ads</div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center my-4">
        <div>ads</div>
      </div>
   
    </div>
    </div>
    
  );
};

export default ImageUploader;
