import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

export default function Slider({ min, max, value, handleChange }) {
  return (
    <div className="slider-container p-3 bg-light rounded shadow-sm  mb-4">
      {/* Display current value */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">{min}</span>
        <span className=" fw-bold">{value}</span>
        <span className="text-muted">{max}</span>
      </div>
      
      {/* Slider Input */}
      <input
        type="range"
        className="form-range slider slider_desing"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        style={{
          width: '100%',
          accentColor: '#007bff', // Custom thumb color
          height: '0.5rem', // Custom track height
        }}
      />
    </div>
  );
}
