import React, { useState } from "react";
import jsPDF from "jspdf";
import "bootstrap/dist/css/bootstrap.min.css";
import Mainnavbar from "./Mainnavbar";

const ImagetoPDF = () => {
  const [images, setImages] = useState([]);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const newImages = [...images];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = () => {
        setImages((prevImages) => [
          ...prevImages,
          { data: reader.result, name: file.name },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = () => {
        setImages((prevImages) => [
          ...prevImages,
          { data: reader.result, name: file.name },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    images.forEach((image, index) => {
      if (index > 0) {
        doc.addPage();
      }
      doc.text(image.name, 10, 10);
      doc.addImage(image.data, "JPEG", 10, 20, 180, 160);
    });
    doc.save("converted_images.pdf");
  };

  return (
    <div className="background_compresser_purple">
      <div className="container-fluid">
        <div>
          <Mainnavbar />
        </div>
        <div className="row maring_top_row">
          <div className="col-md-2 d-flex justify-content-center align-items-center ">
            ads
          </div>
          <div className="col-md-8 p-3">
            <div className=" card  padding_card_section_common">
              <div className="card mb-4">
                <div className="card-body card_pdf_dflex ">

                  
                  <div>
                    <h3 className=" mb-0 purple_color_text_class ">
                      Image to PDF Converter
                    </h3>
                    <div className="text-muted mt-1">
                      This tool converts images to multi-page PDFs as needed.
                    </div>
                  </div>

                  <div className="ms_auto_button">
                    <label
                      htmlFor="image-upload"
                      className="btn button_desoing_purple "
                    >
                      Upload Images
                    </label>
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      multiple
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                    />
                  </div>

                </div>
              </div>
              <div
                className="border p-5 mb-4 text-center"
                style={{ borderStyle: "dashed", backgroundColor: "#f9f9f9" }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                {images.length === 0 ? (
                  <span className="text-muted">Drag and drop images here</span>
                ) : (
                  <div className="row">
                    <div className="text-muted mb-4">
                      Add Many Images You Like
                    </div>
                    {images.map((image, index) => (
                      <div key={index} className="col-12 col-md-4 mb-3">
                        <div className="card">
                          <img
                            src={image.data}
                            alt={image.name}
                            className="card-img-top"
                          />
                          <div className="card-body">
                            <h6 className="card-title text-truncate">
                              {image.name}
                            </h6>
                            <button
                              className="btn compressbuttons_desgin_pdf"
                              onClick={() => handleDelete(index)}
                            >
                              <i class="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        </div>
                        <div className="text-center text-muted">
                          {`page ${index + 1}`}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {images.length > 0 && (
                <div className="text-center">
                  <button
                    className="btn button_desoing_purple "
                    onClick={generatePDF}
                  >
                    Convert to PDF
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-2 d-flex justify-content-center align-items-center ">
            ads
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center my-4">
          <div>ads</div>
        </div>
      </div>
    </div>
  );
};

export default ImagetoPDF;
