import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Mainnavbar from "./Mainnavbar";

const commonRatios = {
  "16:9": [1920, 1080],
  "5:4": [1280, 1024],
  "4:3": [1024, 768],
  "3:2": [1440, 960],
  "8K": [7680, 4320],
  "5K": [5120, 2880],
  "4K": [3840, 2160],
  Retina: [2048, 1536],
  iPhone6plus: [1242, 2208], // Corrected resolution in points for iPhone 6 Plus
  iPhone6: [1334, 750],
  iPhone5: [1136, 640],
  iPad: [1024, 768],
  Twitter: [1024, 512],
  WebBanner: [728, 90],
  VGA: [640, 480],
  HVGA: [320, 480],
};

const AspectRatio = () => {
  const [image, setImage] = useState({ file: null, url: "" });
  const [dimensions, setDimensions] = useState({ width: 1920, height: 1080 });
  const [selectedRatio, setSelectedRatio] = useState("16:9");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage({ file, url: URL.createObjectURL(file) });
    }
  };

  const handleRatioChange = (e) => {
    const ratio = e.target.value;
    setSelectedRatio(ratio);
    setDimensions({
      width: commonRatios[ratio][0],
      height: commonRatios[ratio][1],
    });
  };

  const handleDownload = () => {
    if (!image.file) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    const img = new Image();
    img.src = image.url;

    img.onload = () => {
      // Calculate the aspect ratio scale for proper fitting
      const scale = Math.min(
        canvas.width / img.width,
        canvas.height / img.height
      );
      const x = (canvas.width - img.width * scale) / 2;
      const y = (canvas.height - img.height * scale) / 2;

      // Draw the image on the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous content
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

      // Convert the canvas to a blob and trigger download
      canvas.toBlob(
        (blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "resized_image.png";
          document.body.appendChild(a); // Append to body to support Firefox
          a.click();
          document.body.removeChild(a); // Clean up
        },
        "image/png",
        1.0
      );
    };
  };

  return (
    <div className="background_compresser_blue">
      <div className="container-fluid ">
        <div>
          <Mainnavbar />
        </div>
        <div className=" maring_top_row">
          <div className="row ">
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <div>Ads</div>
            </div>
            <div className="p-3 col-md-8">
            <div className=" card  padding_card_section_common">
              <h2 className="blue_color_text_class textdecoration_title">Resize Your Image</h2>
              <div className="text-muted mb-3">
              This Tool allows users to resize images by selecting desired dimensions.
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row mt-3 mb-4">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="uploadImage" className="mb-2">
                          Upload an image:
                        </label>
                        <input
                          type="file"
                          className="form-control blue_color_text_class blue_color_class_home"
                          id="uploadImage"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group margin_bottom_choose_size">
                    <label htmlFor="selectRatio" className="mb-2">
                      Select Common Ratio:
                    </label>
                    <select
                      className="form-control blue_color_text_class blue_color_class_home"
                      id="selectRatio"
                      value={selectedRatio}
                      onChange={handleRatioChange}
                    >
                      {Object.entries(commonRatios).map(
                        ([ratio, [width, height]]) => (
                          <option key={ratio} value={ratio}>
                            {`${ratio} (${width}x${height})`}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="col text-center ">
                    <button
                      className="btn button_desoing_blue  "
                      onClick={handleDownload}
                      disabled={!image.file}
                    >
                      Download Resized Image
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row my-3">
                    <div className="col text-center">
                      <img
                        src={image.url || "https://via.placeholder.com/300"}
                        alt="Preview"
                        className="img-fluid "
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
           
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <div>Ads</div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center my-4">
            <div>ads</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AspectRatio;
